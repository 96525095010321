var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { title: "自动获取渠道用户ID", footer: null, width: 300 },
          on: { ok: _vm.handleClose },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "margin-bottom": "20px",
                "text-align": "center"
              }
            },
            [
              _c("div", {
                staticClass: "qrcode",
                staticStyle: { width: "300px" },
                attrs: { id: "qrCodeUrl" }
              }),
              _c("vueQr", { attrs: { text: _vm.qrImgUrl } }),
              _c("hr"),
              _c("span", [_vm._v(_vm._s(_vm.payText))])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }